import React from "react"
import Image from '../../../images/top-legado.webp'
import BannerTop from "../../v1/Global/Modules/MediaPush/BannerTop"

const Main = () => {
  return (
    <section className="banner-top legado">
      <BannerTop page={"NOS"}/>
      <img src={Image} className="video" alt="Imagen portada sobre video"/>
      <div className="content text-center">
        <h1 className="white">Una empresa con historia <br /> que mira hacia el futuro</h1>
      </div>
    </section>
  )
}

export default Main
