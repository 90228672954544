import React, { useEffect, useState } from "react"
import lt1 from "../../../images/legado/time-line/lt-1.webp"
import lt2 from "../../../images/legado/time-line/lt-2.webp"
import lt3 from "../../../images/legado/time-line/lt-3.webp"
import lt4 from "../../../images/legado/time-line/lt-4.webp"
import lt5 from "../../../images/legado/time-line/lt-5.webp"
import lt6 from "../../../images/legado/time-line/lt-6.webp"
import lt7 from "../../../images/legado/time-line/lt-7.webp"
import lt8 from "../../../images/legado/time-line/lt-8.webp"
import lt9 from "../../../images/legado/time-line/lt-9.webp"
import lt10 from "../../../images/legado/time-line/lt-10.webp"
import lt11 from "../../../images/legado/time-line/lt-11.webp"
import lt12 from "../../../images/legado/time-line/lt-12.webp"
import lt13 from "../../../images/legado/time-line/lt-13.webp"
import lt14 from "../../../images/legado/time-line/lt-14.webp"
import lt15 from "../../../images/legado/time-line/lt-15.webp"
import AOS from "aos"
import {NextArrow, PrevArrow} from '../arrows'

const HITOS = [
  {
    year: 1867,
    title: "Fundación Adolfo Bullrich y Cía.",
    text: "El 8 de abril de este año, Don Adolfo J. Bullrich funda la casa de remates y subastas que entonces se llamó Adolfo Bullrich y Cía. La firma actuaba en la intermediación de propiedades urbanas y rurales, remates de ganado bovino, ovino y equino, muebles y piezas de arte. En una época en que la Argentina era un país eminentemente rural, Bullrich se estableció como un pilar en la intermediación de propiedades rurales y urbanas, siendo un actor clave en el crecimiento y desarrollo de este mercado.",
    footer_img: "Fachada original de la Casa Bullrich, diseñada por el arquitecto Juan Waldorp.",
    img: lt1,
  },
  {
    year: 1875,
    title: "Primera Exposición de la Sociedad Rural de Palermo",
    text: "Se realiza la primera exposición de la Sociedad Rural de Palermo y la Casa Bullrich fue la encargada de rematar los animales que desfilaban frente al público. Este evento marcó un antes y un después en la historia de la empresa, ya que les permitió consolidarse como referentes en el negocio de la intermediación de propiedades rurales. A partir de ese momento, la empresa comenzó a expandirse rápidamente, y se convirtió en una de las firmas más prestigiosas en el negocio inmobiliario rural de todo el país.",
    footer_img: "Primera exposición de la sociedad rural de Palermo.",
    img: lt2,
  },
  {
    year: 1898,
    title: "Adolfo Bullrich Intendente de la Ciudad de Bs As 1898 -1902",
    text: "Adolfo Bullrich fue intendente de la Ciudad de Buenos Aires desde 1898 hasta 1902. Durante su mandato, se llevaron a cabo diversas obras en la ciudad. Dentro de las obras más destacadas se encuentran: Parque Tres de Febrero, remodelación de Plaza de Mayo, pavimentación de calles, construcción del Mercado de Abasto y ampliación del puerto.",
    footer_img: "Transformación urbana bajo el liderazgo de Adolfo Bullrich: parques, plazas y desarrollo en Bs. As.",
    img: lt3,
  },
  {
    year: 1904,
    title: "Fallecimiento de Don Adolfo y su sucesión familiar",
    text: "Fallece Don Adolfo y quedan al frente de la empresa sus dos hijos, Eduardo y Arturo. Si bien la pérdida de su fundador fue un golpe duro para la empresa, la segunda generación supo continuar con el legado de su padre y expandir aún más la firma, aportando a su proyección.",
    footer_img: "Don Adolfo Bullrich.",
    img: lt4,
  },
  {
    year: 1917,
    title: "Celebración de los primeros 50 años de Casa Bullrich",
    text: "Casa Bullrich celebra sus primeros 50 años de existencia. Este fue un momento de gran importancia para la empresa, ya que les permitió hacer un balance de su trayectoria y reconocer el legado de su fundador y sus sucesores.",
    footer_img: "Medio siglo creciendo junto al campo.",
    img: lt5,
  },
  // {
  //   year: 1921,
  //   title: "Inauguración del edificio Patio Bullrich y llegada de la tercera generación",
  //   text: "Se inaugura el edificio Patio Bullrich como sede de la empresa. En el mismo año, se suman al equipo los hijos de Eduardo y Arturo, integrando la tercera generación a la gestión de la empresa. El edificio Patio Bullrich se convirtió rápidamente en un ícono de la ciudad de Buenos Aires y en un lugar de referencia para aquellos que buscaban adquirir o vender propiedades.",
  //   footer_img: "La Casa Bullrich.",
  //   img: lt6,
  // },
  {
    year: 1923,
    title: "Expansión de Bullrich en todo el territorio argentino",
    text: "Casa Bullrich continuó expandiéndose por todo el territorio argentino, sumando nuevas sucursales y consolidándose como una de las principales empresas del sector. Sin embargo, ese mismo año se produjo un hecho trágico que afectaría profundamente a la familia Bullrich: el fallecimiento repentinamente de Arturo Bullrich, quien había sido uno de los pilares de la institución. A pesar de esta pérdida, la cuarta generación Bullrich se unió para continuar la exitosa trayectoria de la empresa.",
    footer_img: "Mapa del proceso de expansión de la empresa. Ref. Revista 100 años Bullrich.",
    img: lt7,
  },
  {
    year: 1967,
    title: "Celebración del centenario y apogeo de la empresa",
    text: "Casa Bullrich cumplió 100 años de trayectoria, en un momento de gran apogeo y expansión. La empresa contaba con 125.000 hectáreas distribuidas en más de 50 establecimientos en seis provincias, consolidándose como una de las inmobiliarias rurales más importantes del país. La celebración del centenario fue un hito muy importante para la empresa y para la familia Bullrich, que habían logrado mantener y fortalecer el legado de sus antepasados.",
    footer_img: "100 años de éxito y legado",
    img: lt8,
  },
  {
    year: 1982,
    title: "Traslado de la Casa Bullrich al edificio Continental de Diagonal Norte ",
    text: "Posterior a la venta del edificio conocido como la Casa Bullrich, la empresa trasladó sus oficinas al moderno edificio Continental ubicado en Diagonal Norte, donde continuó con sus tradicionales actividades de intermediación en la compra/venta de propiedades rurales.",
    footer_img: "Oficina Edificio Continental.",
    img: lt9,
  },
  // {
  //   year: 1988,
  //   title: "Inauguración del Shopping Center Patio Bullrich",
  //   text: "En 1988, los hermanos Maccarrone decidieron darle un nuevo uso al edificio Patio Bullrich y transformarlo en el primer shopping center de la Argentina. El nuevo centro comercial conservó el apellido de la familia Bullrich y se convirtió en un importante polo de atracción para turistas y vecinos del barrio de Recoleta en Buenos Aires.",
  //   footer_img: "Inauguración del Shopping Center Patio Bullrich.",
  //   img: lt10,
  // },
  {
    year: 1993,
    title: "Reestructuración de la empresa y creación de Bullrich Campos S.A.",
    text: "Se llevó adelante una reestructuración de la empresa original y se crearon diversas empresas con diferentes funciones. Una de ellas fue Bullrich Campos S.A., que inició sus actividades en marzo de ese año como continuadora del Departamento Campos de Adolfo Bullrich y Cía. Ltda. S.A. Esta nueva empresa se constituyó como una Inmobiliaria Rural, dedicada principalmente a la intermediación en la compraventa de establecimientos rurales y propiedades afines.",
    footer_img: "Un crecimiento sostenido junto al campo.",
    img: lt11,
  },
  {
    year: 2010,
    title: "Fundación de la Cámara Argentina de Inmobiliarias Rurales Argentinas (CAIR)",
    text: "Nace la Cámara Argentina de Inmobiliarias Rurales Argentinas (CAIR), cuyo objetivo es representar a los operadores inmobiliarios rurales de todo el país. El primer presidente en gestión fue uno de los socios mayoritarios de Bullrich Campos, Roberto Frenkel Santillan. La fundación de esta cámara fue un paso importante para la consolidación del sector inmobiliario rural en la Argentina y Bullrich Campos actualmente continúa formando parte de esta organización con el fin de aportar al desarrollo del sector.",
    footer_img: "Primera convención de CAIR en la Bolsa de Cereales de Rosario en 2011. Roberto Frenkel Santillan - Primer Presidente en gestión.",
    img: lt12,
  },
  {
    year: 2015,
    title: "Liderazgo en el ranking de Servicios Inmobiliarios Rurales",
    text: "Bullrich Campos S.A. se instala como líder durante 8 años consecutivos, en el ranking publicado por la Revista Mercado junto con Reporte Inmobiliario, dentro del segmento de Servicios Inmobiliarios - Campo. Esta distinción reafirma la posición de la empresa como líder indiscutible en su área de especialización.",
    footer_img: "8 años consecutivos liderando el ranking de Servicios Inmobiliarios Rurales",
    img: lt13,
  },
  {
    year: 2022,
    title: "Tasación de más de 1 millón de hectáreas en un año",
    text: "Bullrich Campos S.A. tasó más de 1 millón de hectáreas en un año, en terrenos de la Patagonia y NEA con extensiones muy grandes. Esta impresionante cifra refleja la capacidad y el alcance de la empresa en el mercado de inmobiliarias rurales, consolidando aún más su posición como líder en el sector.",
    footer_img: "Foto de archivo. Propiedad agropecuaria comercializada por Bullrich Campos.",
    img: lt14,
  },
  {
    year: 2023,
    title: "Referencia y liderazgo en el sector rural con la sexta generación en la gestión",
    text: "Bullrich Campos S.A. continúa siendo una empresa referente y líder en el sector rural, con la incorporación de la sexta generación dentro de su gestión. La empresa cuenta con la mayor trayectoria y experiencia en Inmobiliarias Rurales, lo que le permite brindar un servicio de alta calidad y un amplio conocimiento en el mercado de propiedades rurales.",
    footer_img: "6 generaciones liderando el mercado inmobiliario rural.",
    img: lt15,
  }
]

const Hitos = () => {
  const getSelected = () => {
    if(HITOS[selected]?.year){
      return HITOS[selected]
    }
    else{
      setSelected(0)
      return HITOS[0]
    }
  }

  const [selected, setSelected] = useState(0)
  const [currentHit, setCurrentHit] = useState(getSelected())

  useEffect(() => {
    setCurrentHit(getSelected())
    AOS.refresh();
  }, [selected])

  return (
    <section className="hitos-legado overflow-hidden">
      <div className="container-fluid mb-lg-0 mb-5 ">
        <h2 className="mb-lg-5 mb-5 text-center">Hitos de nuestra historia</h2>
        <div className="row my-lg-5 pt-lg-4 pt-0 justify-content-center my-3 content-items">
          {HITOS.map((hit,index) => (
            <div className={"col-xxl-10 col-lg-11 item " + (selected === index ? 'active' : '')}>
              <div className="row">
                <div className="col-lg-6">
                  <img src={hit.img} alt="Imagen historica de bullrich campos" />
                  <small>{hit.footer_img}</small>
                </div>
                <div className="col-lg-5 offset-lg-1 ps-lg-5 ms-lg-5 second">
                  <div className="tag">{hit.year}</div>
                  <h3>{hit.title}</h3>
                  <p>{hit.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="arrows">
        <div onClick={() => setSelected(selected + 1)} className={"next arrow-custom d-flex d-lg-none " + (selected === HITOS.length - 1 ? 'd-none ' : '')}><i></i><i></i></div>
        <div onClick={() => selected === 0 ? setSelected(HITOS.length - 1) : setSelected(selected - 1)} className={"prev arrow-custom d-flex d-lg-none " + (selected === 0 ? 'd-none ' : '')}><i></i><i></i></div>
      </div>
      <div className="content d-lg-flex d-none">
        <div className="dots">
          {HITOS.map((hito,index) => (
            <div
              onClick={() => setSelected(index)}
              className={"dot " + (selected === index ? "selected" : "")}
            >
              {hito.year}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Hitos
