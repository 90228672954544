import React from "react"
import Image from "../../../images/bullrich-campos.png"
  import Breadcrumbs from '../breadcrumbs'
const Main = () => {
  return (
    <section className="about-legado">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 text-center mb-5 pb-lg-5">
            <Breadcrumbs
              props={[
                { name: 'Bullrich Campos', route: "/", location: "" },
                { name: "Legado", route: "", location: "" },
              ]}
            />
          </div>
          <div className="col-lg-6 mb-3 mb-lg-0">
            <h2>
              Cultivando la excelencia <br /> en cada paso
            </h2>
            <img src={Image} alt="Imagen de portada legado" className="mt-lg-4 d-none d-lg-block logo-img" />
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0 text-container">
            <p>
              <div className="first"> B</div>ullrich Campos es una empresa con
              historia, mirando siempre hacia el futuro. Desde 1867, ha
              combinado su arraigada tradición con la innovación constante de
              cada generación, evolucionando junto al campo.
            </p>
            <p>
              Nuestra marca representa exclusividad y sofisticación, fusionando
              pasado y futuro para ofrecer una experiencia única a los clientes
              más exigentes. Seleccionamos cuidadosamente los campos que
              ofrecemos, garantizando los más altos estándares de calidad.
            </p>
            <p>
              Con más de 150 años de experiencia en tasaciones y operaciones
              inmobiliarias exitosas, somos la empresa líder en el sector.
              Agregamos valor diferencial a nuestros clientes y nos esforzamos
              por dejar un legado que trascienda generaciones, aportando al
              desarrollo y crecimiento económico del campo y del país.
            </p>
            <p>
              Conocemos las particularidades y necesidades de nuestros clientes
              de manera profunda y detallada, siempre fieles a nuestros valores
              y principios. Somos sinónimo de garantía, confianza y credibilidad
              en el mercado inmobiliario rural.
            </p>
          </div>
          <div className="col-lg-12 mt-lg-5 pt-5">
            <div className="d-flex justify-content-start flex-wrap justify-content-lg-between">
              <div className="item-history">
                <div className="d-flex align-items-lg-end align-items-center justify-content-lg-start justify-content-center text-center text-lg-start">
                  <h2 className="bold">156</h2>
                  <span className="bold">Años</span>
                </div>
                <p>
                  de trayectoria y <br /> especialización.
                </p>
              </div>
              <div className="item-history">
                <div className="d-flex align-items-lg-end align-items-center justify-content-lg-start justify-content-center text-center text-lg-start">
                  <h2 className="bold">6</h2>
                  <span className="bold">Generaciones</span>
                </div>
                <p>
                  de invaluable experiencia <br /> acumulada.
                </p>
              </div>
              <div className="item-history">
                <div className="d-flex align-items-lg-end align-items-center justify-content-lg-start justify-content-center text-center text-lg-start">
                  <h2 className="bold">N 1</h2>
                  <span className="bold">En servicios</span>
                </div>
                <p>
                  inmobiliarios rurales, <br /> 8 años consecutivos.
                </p>
              </div>
              <div className="item-history">
                <div className="d-flex align-items-lg-end align-items-center justify-content-lg-start justify-content-center text-center text-lg-start">
                  <h2 className="bold">+10</h2>
                  <span className="bold">Millones</span>
                </div>
                <p>
                  hectáreas recorridas <br /> tasadas y vendidas.
                </p>
              </div>
              <div className="item-history">
                <div className="d-flex align-items-lg-end align-items-center justify-content-lg-start justify-content-center text-center text-lg-start">
                  <h2 className="bold">250</h2>
                  <span className="bold">Mil</span>
                </div>
                <p>
                  Hectáreas de campos tasadas <br /> por año de forma
                  personalizada.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
