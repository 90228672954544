import React from "react"
import { getTextShort, getDateTime } from "../../helpers/helper.novelty"
import { Link, navigate } from "gatsby"
import { clearNoveltyAction } from "../../redux/noveltiesDucks"
import Loading from './Loading'
import { connect } from "react-redux"

const NoveltyCard = props => {
  const { novelty, featured, skeleton } = props

  return (
    <div className={"card novelty " + (skeleton && 'skeleton ') + (featured && ' featured ')}>
      {skeleton && <Loading />}
      <img src={novelty?.image} alt="Portada de la novedad" />
      <div className="content white">
        <div className="date small mb-lg-5 text-uppercase">{getDateTime(novelty?.date_published, true)}</div>
        <h3 className="mb-0">{getTextShort(novelty?.title, 80)}</h3>
        <div className="onHover">
          <p className="py-lg-4 my-lg-2">
            {getTextShort(novelty?.headline, 120)}
          </p>
          <Link
            to={"/novedades/" + novelty?.id + "/" + novelty?.slug}
            className="btn white border mt-2"
          >
            Ver más
          </Link>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({}), null)(NoveltyCard)
