import * as React from "react"
import Layout from "../../components/v2/layout"
import Seo from "../../components/seo"
import Main from '../../components/v2/legado/main.jsx'
import About from '../../components/v2/legado/about.jsx'
import Experience from '../../components/v2/legado/experience.jsx'
import Video from '../../components/v2/legado/video.jsx'
import Team from '../../components/v2/legado/team.jsx'
import Hitos from '../../components/v2/legado/hitos.jsx'
import Circular from '../../components/v2/legado/circular.jsx'

//Components

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
      
  return(
  <Layout>
    <Seo title="Creciendo junto al campo desde 1867" description="Más de 150 años de experiencia en el sector rural. Conozca los campos más destacados de la Argentina, servicio de tasación satelital, divisiones y consultoría."/>
    <Main />
    <About />
    <Experience />
    <Video />
    <Team />
    <Hitos />
    <Circular />
  </Layout>
)}

export default IndexPage
