import React from "react"
import Image from "../../../images/video-legado.png"
import YouTube from "react-youtube"

const opts = {
  height: "100%",
  width: "90%",
  playerVars: {
    autoplay: 0,
    rel:0,
    controls: 0, // Oculta los controles del reproductor
    modestbranding: 1, // Oculta el logotipo de YouTube en la barra de reproducción
    showinfo: 0, // Oculta el título y la información del canal
  },
}

const Video = () => {
  return (
    <section className="video-legado">
      <div className="container-fluid text-center">
        <h2 className="mb-lg-5 mb-5">
          Nuestro secreto está en conocer <br /> el campo mejor que nadie
        </h2>
        <YouTube videoId={"9hHQb9zvg-M"} opts={opts} />
      </div>
    </section>
  )
}

export default Video
