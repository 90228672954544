import React from "react"
import Equipo1 from "../../../images/legado/equipo1.webp"
import Equipo2 from "../../../images/legado/equipo2.webp"
import Equipo3 from "../../../images/legado/equipo3.webp"

const JSON_TEAM = [{
  id:0,
  name:"Roberto F. Santilllán",
  text:"Socio",
  img: Equipo2
},{
  id:1,
  name:"Sebastián Bullrich",
  text:"Socio",
  img: Equipo1
},{
  id:2,
  name:"Sebastián Giusto Bullrich",
  text:"Socio",
  img: Equipo3
}] 

const Team = () => {
  return (
    <section className="team-legado">
      <div className="container-fluid text-center">
        <h2 className="mb-lg-5 mb-3">Expertise en el negocio <br className="d-block d-lg-none" /> inmobiliario rural</h2>
        <h3>Profesionalismo & Compromiso</h3>
        <p className="text-justify mx-auto"> <div className="first"> E</div>n Bullrich Campos, el latir de nuestra empresa proviene de cada uno de nuestros socios y miembros del equipo, que con pasión y entrega, hacen posible nuestro liderazgo en el sector. La excelencia y especialización son los pilares de nuestro trabajo, que nos permiten alcanzar los objetivos más ambiciosos de nuestros clientes, ofreciéndoles una experiencia única y exclusiva.</p>
        <div className="row justify-content-center">
          <div className="col-lg-12 d-lg-flex justify-content-center">
            {JSON_TEAM.map(({id, name, text, img}) =>(
              <div className="member" key={id}>
                <img src={img} alt={name} />
                <h5>{name}</h5>
                <small className="bold">{text}</small>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
