import React from "react"
import { connect } from "react-redux"
import { getNoveltiesCircular } from "../../../helpers/helper.novelty"
import NoveltyCard from '../novelty-card'
import { useGetBlogsQuery } from "../../../redux/mediacore/blog"

const Circular = () => {

  const { data: allBlogData, isLoading, isError } = useGetBlogsQuery(26);

  return getNoveltiesCircular(allBlogData?.news).length > 0 && !isLoading ? (
    <section className="circular-legado">
      <div className="container-fluid text-center">
        <h2>Circular Bullrich</h2>
        <p className="mt-lg-4">
          <div className="first"> B </div> ienvenido a este espacio donde
          compartimos los acontecimientos más destacados de nuestra historia.
          <br />
          <br />
          Una colección de recuerdos, eventos e hitos, que son un fiel reflejo
          de nuestra trayectoria. Hemos sido testigos y protagonistas de la
          evolución de la industria, enfrentando desafíos y superando obstáculos
          para mantenernos siempre a la vanguardia. Te invitamos a descubrir
          nuestra historia y conocer cómo hemos construido una empresa de
          renombre con dedicación, profesionalismo y compromiso con la
          excelencia.
        </p>
      </div>
      {<div className="d-flex flex-column flex-lg-row mt-lg-5 mt-4">
        {(getNoveltiesCircular(allBlogData?.news)).map((n, i) => (
          <NoveltyCard skeleton={isLoading ? true : false} novelty={n}/>
        ))}
      </div>}
    </section>
  ):''
}

export default connect(
  state => ({
    loading_novelties: state.novelties.loading_novelties,
    novelties: state.novelties.novelties,
    tags: state.novelties.tags,
  }),
  null
)(Circular)
