import React from "react"
import Image1 from "../../../images/img-1-legado.png"
import Image2 from "../../../images/img-2-legado.png"
import Image3 from "../../../images/img-3-legado.png"
import Image4 from "../../../images/img-4-legado.png"

const Main = () => {
  return (
    <section className="experience-legado">
      <div className="container-fluid">
        <div className="d-flex justify-content-center align-items-center aos-init" data-aos="fade-left">
          <img src={Image1} alt="Imagen pequeña de experiencia" />
          <h1 className="mb-0 mx-lg-5 px-lg-5 d-block">LA EXPERIENCIA QUE</h1>
          <img src={Image2} alt="Imagen pequeña de experiencia" />
        </div>
        <div className="d-flex  justify-content-center align-items-center aos-init" data-aos="fade">
          <h1 className="mb-0 mt-lg-3 mx-lg-5 d-block">SOLO POSEE QUIÉN ACOMPAÑA</h1>
        </div>
        <div className="d-flex justify-content-center align-items-center aos-init" data-aos="fade-right">
          <img src={Image3} alt="Imagen pequeña de experiencia" />
          <h1 className="mb-0 mx-lg-5 px-lg-5 d-block">AL CAMPO DESDE 1867</h1>
          <img src={Image4} alt="Imagen pequeña de experiencia" />
        </div>
      </div>
    </section>
  )
}

export default Main
